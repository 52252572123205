/*-- -------------------------- -->
<---     Mobile Navigation      -->
<--- -------------------------- -*/
body,
html {
  /* reset margin and padding so there's no gap between the nav and the screen edges */
  margin: 0;
  padding: 0;
}
/* Mobile - 1023px */
@media only screen and (max-width: 63.9375rem) {
  body.cs-open {
    overflow: hidden;
  }
  #cs-navigation {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 0.75rem 1rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
  }
  #cs-navigation:after {
    /* on hover green bar */
    content: "";
    width: 40%;
    max-width: 9.125rem;
    height: 100%;
    background: #fff;
    opacity: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0%;
    z-index: -1;
    transition: width 0.2s, max-width 0.3s, background-color 0.3s;
  }
  #cs-navigation:before {
    content: "";
    width: 100%;
    height: 0vh;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -11;
    transition: height 0.5s, opacity 0.3s;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
  #cs-navigation.cs-active:after {
    width: 100%;
    max-width: 100%;
    background-color: #484848;
  }
  #cs-navigation.cs-active:before {
    height: 150vh;
    opacity: 1;
  }
  #cs-navigation.cs-active .cs-top-bar {
    z-index: initial;
  }
  #cs-navigation.cs-active .cs-top-bar:before {
    display: none;
  }
  #cs-navigation.cs-active .cs-link {
    color: var(--bodyTextColorWhite);
  }
  #cs-navigation.cs-active .cs-ul-wrapper {
    opacity: 1;
    transform: scaleY(1);
    transition-delay: 0.2s;
  }
  #cs-navigation.cs-active .cs-li {
    opacity: 1;
    transform: translateY(0);
  }
  #cs-navigation.scroll .cs-top-bar {
    height: 0;
    padding-bottom: 0;
    opacity: 0;
    overflow: hidden;
  }
  #cs-navigation.scroll .cs-top-bar:before {
    opacity: 0;
  }
  #cs-navigation.scroll .cs-toggle {
    margin-top: 0;
  }
  #cs-navigation .cs-top-bar {
    margin: 0;
    padding: 0 0 0.75rem 0;
    position: relative;
    z-index: -3;
    transition: height 0.3s, padding-bottom 0.3s, opacity 0.3s;
  }
  #cs-navigation .cs-top-bar:before {
    /* grey line */
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    opacity: 1;
    display: block;
    position: absolute;
    right: -1rem;
    bottom: 0;
    z-index: -2;
    transition: opacity 0.3s;
  }
  #cs-navigation .cs-logo {
    width: 40%;
    height: 200%;
    /* prevents padding and border from affecting height and width */
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -35px;
    left: 0;
    z-index: 10;
  }
  #cs-navigation .cs-logo img {
    width: 100b;
    height: 100%;
    /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
    object-fit: contain;
  }
  #cs-navigation .cs-item {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  #cs-navigation .cs-remove {
    display: none;
  }
  #cs-navigation .cs-picture {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    background-color: #eff1f0;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
  }
  #cs-navigation .cs-icon {
    width: 1rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-header {
    display: none;
  }
  #cs-navigation .cs-link {
    font-size: 0.875rem;
    line-height: 1.5em;
    text-align: inherit;
    text-decoration: none;
    margin: 0;
    color: var(--bodyTextColor);
    display: block;
    transition: color 0.3s;
  }
  #cs-navigation .cs-toggle {
    /* 44px - 48px */
    width: clamp(2.75rem, 6vw, 3rem);
    height: clamp(2.75rem, 6vw, 3rem);
    margin: 0.75rem 0 0 auto;
    background-color: #484848;
    border: none;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #cs-navigation .cs-active .cs-line1 {
    top: 50%;
    transform: translate(-50%, -50%) rotate(225deg);
  }
  #cs-navigation .cs-active .cs-line2 {
    top: 50%;
    transform: translate(-50%, -50%) translateY(0) rotate(-225deg);
    transform-origin: center;
  }
  #cs-navigation .cs-active .cs-line3 {
    opacity: 0;
    bottom: 100%;
  }
  #cs-navigation .cs-box {
    /* 24px - 28px */
    width: clamp(1.5rem, 2vw, 1.75rem);
    /* 14px - 16px */
    height: clamp(0.875rem, 1.5vw, 1rem);
    position: relative;
  }
  #cs-navigation .cs-line {
    width: 100%;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  #cs-navigation .cs-line1 {
    top: 0;
    transition: transform 0.5s, top 0.3s, left 0.3s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-fill-mode: forwards;
    transform-origin: center;
  }
  #cs-navigation .cs-line2 {
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: top 0.3s, left 0.3s, transform 0.5s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  #cs-navigation .cs-line3 {
    bottom: 0;
    transition: bottom 0.3s, opacity 0.3s;
  }
  #cs-navigation .cs-ul-wrapper {
    width: 100%;
    background-color: #fff;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0px 8px 24px;
    opacity: 0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: -1;
    overflow: hidden;
    transform: scaleY(0);
    transition: transform 0.4s, opacity 0.3s;
    transform-origin: top;
  }
  #cs-navigation .cs-ul {
    width: 100%;
    height: auto;
    max-height: 65vh;
    margin: 0;
    padding: 3rem 0 3rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25rem;
    overflow: scroll;

  }
  #cs-navigation .cs-li {
    text-align: center;
    font-size: 2rem;
    list-style: none;
    width: 100%;
    margin-right: 0;
    opacity: 0;
    /* transition from these values */
    transform: translateY(-4.375rem);
    transition: transform 0.6s, opacity 0.9s;
  }
  #cs-navigation .cs-li:nth-of-type(1) {
    transition-delay: 0.05s;
  }
  #cs-navigation .cs-li:nth-of-type(2) {
    transition-delay: 0.1s;
  }
  #cs-navigation .cs-li:nth-of-type(3) {
    transition-delay: 0.15s;
  }
  #cs-navigation .cs-li:nth-of-type(4) {
    transition-delay: 0.2s;
  }
  #cs-navigation .cs-li:nth-of-type(5) {
    transition-delay: 0.25s;
  }
  #cs-navigation .cs-li:nth-of-type(6) {
    transition-delay: 0.3s;
  }
  #cs-navigation .cs-li:nth-of-type(7) {
    transition-delay: 0.35s;
  }
  #cs-navigation .cs-li:nth-of-type(8) {
    transition-delay: 0.4s;
  }
  #cs-navigation .cs-li:nth-of-type(9) {
    transition-delay: 0.45s;
  }
  #cs-navigation .cs-li-link {
    /* 16px - 24px */
    font-size: clamp(2rem, 2.5vw, 1.5rem);
    line-height: 1.2em;
    text-decoration: none;
    margin: 0;
    color: var(--headerColor);
    display: inline-block;
    position: relative;
  }
  #cs-navigation .cs-li-link:before {
    /* active state underline */
    content: "";
    width: 100%;
    height: 1px;
    background: currentColor;
    opacity: 1;
    display: none;
    position: absolute;
    bottom: -0.125rem;
    left: 0;
  }
  #cs-navigation .cs-li-link.cs-active:before {
    display: block;
  }
  #cs-navigation .cs-button-solid {
    display: none;
  }
}
/* Tablet - 650px - 1023px */
@media only screen and (min-width: 40.625rem) and (max-width: 63.9375rem) {
  #cs-navigation .cs-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  #cs-navigation .cs-item {
    position: relative;
  }
  #cs-navigation .cs-item:nth-of-type(2):after {
    display: none;
  }
  #cs-navigation .cs-item:after {
    /* divider line */
    content: "";
    width: 1px;
    height: 100%;
    margin: 0 1rem;
    background: #eff1f0;
    opacity: 1;
    display: block;
    position: relative;
  }
  #cs-navigation .cs-picture {
    display: flex;
  }
  #cs-navigation .cs-ul {
    padding-top: 4.6875rem;
    gap: 2rem;
  }
  #cs-navigation .cs-li-link {
    font-size: 1.5rem;
  }
}
/*-- -------------------------- -->
<---     Desktop Navigation     -->
<--- -------------------------- -*/
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #cs-navigation {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    padding: 1rem 1rem 0 1rem;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: fixed;
    z-index: 10000;
    transition: padding-top 0.3s;
  }
  #cs-navigation.scroll {
    padding-top: 0;
  }
  #cs-navigation.scroll .cs-top-bar {
    height: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    opacity: 0;
    overflow: hidden;
    transform: translateY(-3.125rem);
  }
  #cs-navigation.scroll .cs-top-bar:before {
    width: 0;
  }
  #cs-navigation.scroll .cs-toggle {
    margin-top: 0;
  }
  #cs-navigation .cs-toggle {
    display: none;
  }
  #cs-navigation .cs-logo {
    width: 12.4%;
    max-width: 20rem;
    height: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    /* 32px - 48px */
    padding: 1rem clamp(2rem, 3vw, 3rem);
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
  #cs-navigation .cs-logo img {
    width: 150%;
    height: auto;
    object-fit: contain;
    /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
  }
  #cs-navigation .cs-top-bar {
    width: 100%;
    margin: 0;
    padding: 0 0 1rem 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    transition: height 0.3s, opacity 0.3s, padding-bottom 0.3s, margin-bottom 0.3s, transform 0.3s;
  }
  #cs-navigation .cs-top-bar:before {
    /* grey line */
    content: "";
    width: 100%;
    height: 1px;
    background: #eff1f0;
    opacity: 1;
    display: block;
    position: absolute;
    right: -1rem;
    bottom: 0;
    z-index: -2;
    transition: width 0.3s;
  }
  #cs-navigation .cs-item {
    list-style: none;
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }
  #cs-navigation .cs-item:last-of-type:after {
    display: none;
  }
  #cs-navigation .cs-item:hover .cs-picture {
    transform: scale(1.1);
  }
  #cs-navigation .cs-item:after {
    /* divider line */
    content: "";
    width: 1px;
    height: 3rem;
    /* 24px - 44px */
    margin: 0 clamp(1.5rem, 5vw, 2.75rem);
    background: #eff1f0;
    opacity: 1;
    display: block;
    position: relative;
  }
  #cs-navigation .cs-picture {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    background-color: #eff1f0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s;
  }
  #cs-navigation .cs-icon {
    width: 1.5rem;
    height: auto;
    display: block;
  }
  #cs-navigation .cs-header {
    font-size: 1rem;
    font-weight: 700;
    margin: 0;
    color: var(--headerColor);
    display: block;
  }
  #cs-navigation .cs-link {
    font-size: 0.875rem;
    line-height: 1.5em;
    text-align: inherit;
    text-decoration: none;
    margin: 0;
    color: var(--bodyTextColor);
    display: block;
    transition: color 0.3s;
  }
  #cs-navigation .cs-link:hover {
    text-decoration: underline;
  }
  #cs-navigation .cs-bottom-bar {
    width: 100%;
    /* prevents padding and border from affecting height and width */
    box-sizing: border-box;
    /* 235px - 420px */
    padding: 0 0 0 clamp(14.6875rem, 23vw, 26.25rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }
  #cs-navigation .cs-ul {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* 20px - 36px */
    gap: clamp(1.25rem, 2.6vw, 2.25rem);
  }
  #cs-navigation .cs-li {
    list-style: none;
    padding: 2rem 0;
    /* prevent flexbox from squishing it */
    flex: none;
  }
  #cs-navigation .cs-li:last-of-type {
    /* pushes the button to the far roght */
    margin-left: auto;
    padding: 0;
  }
  #cs-navigation .cs-li-link {
    /* 14px - 16px */
    font-size: clamp(1.3rem, 1vw, 1rem);
    line-height: 1.5em;
    text-decoration: none;
    margin: 0;
    color: var(--bodyTextColor);
    display: block;
    position: relative;
  }
  #cs-navigation .cs-li-link:hover:before {
    width: 100%;
  }
  #cs-navigation .cs-li-link.cs-active:before {
    width: 100%;
  }
  #cs-navigation .cs-li-link:before {
    /* active state underline */
    content: "";
    width: 0%;
    height: 2px;
    background: var(--primary);
    opacity: 1;
    display: block;
    position: absolute;
    bottom: 0rem;
    left: 0;
    transition: width 0.3s;
  }
  #cs-navigation .cs-button-solid {
    font-size: 1rem;
    font-weight: 700;
    /* 46px - 56px */
    line-height: clamp(2.875em, 5.5vw, 3.5em);
    text-align: center;
    text-decoration: none;
    min-width: 9.375rem;
    margin: 0;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
    padding: 0 1.5rem;
    color: #fff;
    background-color: var(--primary);
    border-radius: 0.25rem;
    display: inline-block;
    position: relative;
    z-index: 1;
  }
  #cs-navigation .cs-button-solid:before {
    content: "";
    width: 0%;
    height: 100%;
    background: #000;
    opacity: 1;
    border-radius: 0.25rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s;
  }
  #cs-navigation .cs-button-solid:hover:before {
    width: 100%;
  }
  #cs-navigation .cs-nav-button {
    margin-left: auto;
    border-radius: 0;
  }
}
                                