/*-- -------------------------- -->
<---            FAQ             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #faq-1263 {
        padding: var(--sectionPadding);
        background-color: #fff;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }
    #faq-1263 .cs-topper {
        color: var(--primary);
    }
    #faq-1263 .cs-button-solid {
        font-size: 1rem;
        font-weight: 700;
        /* 46px - 56px */
        line-height: clamp(2.875rem, 5.5vw, 3.5rem);
        text-align: center;
        text-decoration: none;
        min-width: 9.375rem;
        margin: 0;
        /* prevents padding from adding to the width */
        box-sizing: border-box;
        /* 32px - 48px */
        padding: 0 clamp(2rem, 4vw, 3rem);
        background-color: #D4AF37;
        color: #fff;
        display: inline-block;
        position: relative;
        z-index: 1;
        transition: color 0.3s;
        transition: background-color 0.3s;
      }
      #faq-1263 .cs-button-solid:hover {
        background-color: #000;
        color: #fff;
      }
    #faq-1263 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* 40px - 48px */
        gap: clamp(2.5rem, 5vw, 3rem);
    }
    #faq-1263 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }

    #faq-1263 .cs-title {
        margin: 0;
        color:  #5F5F5F;
        font-size: 2.2rem;
    }
    #faq-1263 .cs-faq-group {
        width: 100%;
        max-width: 52.875rem;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* 16px - 20px */
        gap: clamp(1rem, 2.4vw, 1.25rem);
    }
    #faq-1263 .cs-faq-item {
        list-style: none;
        width: 100%;
        background-color: #f7f7f7;
        border-radius: 0.5rem;
        padding: 0;
        box-sizing: border-box;
        transition: border-bottom 0.3s;
    }
    #faq-1263 .cs-faq-item.active .cs-button {
        color: var(--primary);
    }
    #faq-1263 .cs-faq-item.active .cs-button:before {
        background-color: var(--primary);
        transform: rotate(315deg);
    }
    #faq-1263 .cs-faq-item.active .cs-button:after {
        background-color: var(--primary);
        transform: rotate(-315deg);
    }
    #faq-1263 .cs-faq-item.active .cs-item-p {
        height: auto;
        margin-top: 0.75rem;
        /* 16px - 24px */
        padding-top: 0.75rem;
        padding-bottom: 1.5rem;
        opacity: 0.8;
    }
    #faq-1263 .cs-faq-item.active .cs-item-p:before {
        /* border top */
        content: "";
        height: 1px;
        background: #5F5F5F;
        opacity: 1;
        position: absolute;
        display: block;
        top: 0;
        left: 1.5rem;
        right: 1.5rem;
    }
    #faq-1263 .cs-button {
        /* 16px - 20px */
        font-size: clamp(1rem, 2vw, 1.25rem);
        line-height: 1.2em;
        text-align: left;
        font-weight: bold;
        width: 100%;
        padding: 1.5rem 1.5rem 0;
        border: none;
        background: transparent;
        color: #5F5F5F;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        /* 16px - 24px */
        gap: clamp(1rem, 2.5vw, 1.5rem);
        position: relative;
        transition:
            background-color 0.3s,
            color 0.3s;
    }
    #faq-1263 .cs-button:hover {
        cursor: pointer;
    }
    #faq-1263 .cs-button:before {
        /* left line */
        content: "";
        width: 0.5rem;
        height: 0.125rem;
        background-color: #5F5F5F;
        opacity: 1;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 67%;
        right: 1.1875rem;
        transform: rotate(45deg);
        /* animate the transform from the left side of the x axis, and the center of the y */
        transform-origin: left center;
        transition: transform 0.5s;
    }
    #faq-1263 .cs-button:after {
        /* right line */
        content: "";
        width: 0.5rem;
        height: 0.125rem;
        background-color: #5F5F5F;
        opacity: 1;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 67%;
        right: 1rem;
        transform: rotate(-45deg);
        /* animate the transform from the right side of the x axis, and the center of the y */
        transform-origin: right center;
        transition: transform 0.5s;
    }
    #faq-1263 .cs-button-text {
        width: 90%;
        padding: 0;
        display: block;
    }
    #faq-1263 .cs-item-p {
        /* 14px - 16px */
        font-size: clamp(0.875rem, 1.5vw, 1rem);
        line-height: 1.5em;
        width: 100%;
        height: 0;
        margin: 0;
        /* 16px - 24px */
        padding: 0 1.5rem 1.5rem;
        /* prevents padding and border from affecting height and width */
        box-sizing: border-box;
        opacity: 0;
        color: var(--bodyTextColor);
        /* clips the text so it doesn't show up */
        overflow: hidden;
        position: relative;
        transition:
            opacity 0.3s,
            padding-bottom 0.3s,
            padding-top 0.3s;
    }
    #faq-1263 .cs-background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    #faq-1263 .cs-background img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

                                