/*-- -------------------------- -->
<---   Side By Side Reverse     -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #sbsr-1279 {
        padding: var(--sectionPadding);
        overflow-x: hidden;
        position: relative;
        z-index: 1;
    }
    #sbsr-1279 .cs-container {
        width: 100%;
        /* changes to 1280px at desktop */
        max-width: 53.125rem;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: column;
        /* 48px - 108px */
        gap: clamp(3rem, 5vw, 6.75rem);
    }
    #sbsr-1279 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: left;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: flex-start;
        /* sends it to the bottom in the 2nd position */
        order: 2;
    }

    #sbsr-1279 .cs-title {
        color: #D4AF37;
        font-weight: 200;
        font-size: 2rem;
        max-width: 20ch;
    }
    #sbsr-1279 .cs-text {
        margin-bottom: 1rem;
        color: #5F5F5F;
        font-size: 1.2rem;
    }
    #sbsr-1279 .cs-text:last-of-type {
        margin-bottom: 2rem;
    }
    #sbsr-1279 .cs-button-solid {
        font-size: 1rem;
        /* 46px - 56px */
        line-height: clamp(2.875em, 5.5vw, 3.5em);
        text-decoration: none;
        font-weight: 700;
        text-align: center;
        margin: 0;
        color: #fff;
        border: none;
        min-width: 9.375rem;
        padding: 0 1.5rem;
        background-color: var(--primary);
        border-radius: 0.25rem;
        overflow: hidden;
        display: inline-block;
        position: relative;
        z-index: 1;
        /* prevents padding from adding to the width */
        box-sizing: border-box;
        transition: color 0.3s;
    }
    #sbsr-1279 .cs-button-solid:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 0%;
        background: #000;
        opacity: 1;
        top: 0;
        left: 0;
        z-index: -1;
        transition: width 0.3s;
    }
    #sbsr-1279 .cs-button-solid:hover {
        color: #fff;
    }
    #sbsr-1279 .cs-button-solid:hover:before {
        width: 100%;
    }
    #sbsr-1279 .cs-picture {
        width: 100%;
        /* 456px - 598px */
        height: clamp(28.5rem, 46vw, 37.375rem);
        border-radius: 1.25rem;
        overflow: hidden;
        display: block;
        /* sends it to the bottom in the 2nd position */
        order: 2;
        position: relative;
        z-index: 1;
    }
    #sbsr-1279 .cs-picture:before {
        /* black overlay */
        content: "";
        width: 100%;
        height: 100%;
        background: #1a1a1a;
        opacity: 0.3;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #sbsr-1279 .cs-picture img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    #sbsr-1279 .cs-background {
        width: 100%;
        height: 50%;
        background-color: #5F5F5F;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    #sbsr-1279 .cs-background img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
    #sbsr-1279 .cs-content {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 2rem;
    }
    #sbsr-1279 .cs-flex {
        width: 45%;
    }
    #sbsr-1279 .cs-title {
        color: #D4AF37;
        font-weight: 200;
        margin: 0;
    }
    #sbsr-1279 .cs-text {
        color: #5F5F5F;
        width: 50%;
    }
    #sbsr-1279 .cs-button-solid {
        margin-right: auto;
    }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #sbsr-1279 {
        /* moved padding back to the section container */
        padding: var(--sectionPadding);
    }
    #sbsr-1279 .cs-container {
        max-width: 80rem;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    #sbsr-1279 .cs-content {
        width: 100%;
        max-width: 27.0625rem;
        margin: 0;
        flex-direction: column;
        row-gap: 0;
        order: 1;
    }
    #sbsr-1279 .cs-flex {
        width: 100%;
    }
    #sbsr-1279 .cs-title {
        font-weight: 200;
        margin: 0 0 1rem 0;
        color: #D4AF37;
    }
    #sbsr-1279 .cs-text {
        color: #5F5F5F;
        width: 100%;
    }
    #sbsr-1279 .cs-picture {
        max-width: 46.125rem;
    }
    #sbsr-1279 .cs-background {
        width: 60vw;
        height: 100%;
        /* 172px - 336px */
        margin-left: clamp(11.125rem, 30vw, 21rem);
        left: 50%;
        top: 0;
    }
}

                                
