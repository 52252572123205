/*-- -------------------------- -->
<---       Meet The Team        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #meet-us-348 {
        padding: var(--sectionPadding);
        background-color: #fffF;
        overflow-x: hidden;
    }
    #meet-us-348 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    #meet-us-348 .cs-content {
        /* set text align to left if content needs to be left aligned */
        text-align: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* centers content horizontally, set to flex-start to left align */
        align-items: center;
    }

    #meet-us-348 .cs-title {
        font-size: var(--headerFontSize);
        font-weight: 200;
        line-height: 1.2em;
        text-align: inherit;
        max-width: 43.75rem;
        margin: 0 0 1rem 0;
        color: #D4AF37;
        position: relative;
    }
    #meet-us-348 .cs-text {
        font-size: var(--bodyFontSize);
        line-height: 1.5em;
        text-align: inherit;
        width: 100%;
        max-width: 40.625rem;
        margin: 0;
        color: var(--bodyTextColor);
    }

    #meet-us-348 .cs-card-group {
        width: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }
    #meet-us-348 .cs-item {
        list-style: none;
        width: 100%;
        max-width: 21.5rem;
        border-radius: 1rem;
        /* clips corners */
        overflow: hidden;
        position: relative;
    }
    #meet-us-348 .cs-item:hover .cs-background {
        background-color: var(--primary);
    }
    #meet-us-348 .cs-item:hover .cs-background img {
        transform: scale(1.1);
        opacity: 0.9;
    }
    #meet-us-348 .cs-item:hover .cs-name {
        color: var(--primary);
    }
    #meet-us-348 .cs-info {
        padding: 0.75rem 1rem;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10;
    }
    #meet-us-348 .cs-name {
        font-size: 1.25rem;
        font-weight: 200;
        color: #D4AF37;
        line-height: 1.2em;
        margin: 0;
        display: block;
        transition: color 0.3s;
    }
    #meet-us-348 .cs-job {
        /* 14px - 16px */
        font-size: clamp(0.875rem, 1.5vw, 1rem);
        line-height: 1.5em;
        color: var(--bodyTextColor);
        display: block;
    }
    #meet-us-348 .cs-background {
        width: 100%;
        height: 21.25rem;
        overflow: hidden;
        display: block;
        position: relative;
        transition: background-color 0.3s;
    }
    #meet-us-348 .cs-background img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        /* makes it behave like a background image */
        object-fit: cover;
        /* makes the image position itself at the top of the parent */
        object-position: top;
        transition:
            transform 0.6s,
            opacity 0.3s;
    }
    #meet-us-348 .cs-bubbles {
        display: none;
    }
}
/* Tablet - 700px */
@media only screen and (min-width: 43.75rem) {
    #meet-us-348 .cs-title {
        margin-bottom: 0;
    }
    #meet-us-348 .cs-button-solid {
        margin: 0;
    }
    #meet-us-348 .cs-card-group {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    #meet-us-348 .cs-item {
        width: 48.8%;
    }
}
/* Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #meet-us-348 .cs-content {
        max-width: 100%;
    }
    #meet-us-348 .cs-card-group {
        flex-wrap: nowrap;
        justify-content: center;
    }
    #meet-us-348 .cs-item {
        /* stretch the list items as far as they can go, and the gap property on the parent will space them apart */
        width: 100%;
    }
    #meet-us-348 .cs-background {
        /* 250px - 335px */
        height: clamp(15.625rem, 25vw, 20.9375rem);
    }
    #meet-us-348 .cs-bubbles {
        display: block;
        position: absolute;
    }
    #meet-us-348 .cs-bubbles-small {
        width: 13.875rem;
        height: 9.5rem;
        left: -15.625rem;
        top: -3.125rem;
    }
    #meet-us-348 .cs-bubbles-small:before {
        /* circle 1 */
        content: "";
        width: 8.75rem;
        height: 8.75rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        animation-name: floatAnimation2;
        animation-duration: 6s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    #meet-us-348 .cs-bubbles-small:after {
        /* circle 2 */
        content: "";
        width: 7.3125rem;
        height: 7.3125rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        animation-name: floatAnimation;
        animation-duration: 20s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    #meet-us-348 .cs-bubbles-big {
        width: 19.75rem;
        height: 24.125rem;
        right: -18.75rem;
        bottom: -6.25rem;
    }
    #meet-us-348 .cs-bubbles-big:before {
        /* circle 1 */
        content: "";
        width: 5.875rem;
        height: 5.875rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        animation-name: floatAnimation2;
        animation-duration: 6s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    #meet-us-348 .cs-bubbles-big:after {
        /* circle 2 */
        content: "";
        width: 5.875rem;
        height: 5.875rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        top: 4.375rem;
        right: 3.75rem;
        animation-name: floatAnimation;
        animation-duration: 20s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    #meet-us-348 .cs-bubbles-big .cs-bubbles-big2 {
        width: 13.1875rem;
        height: 13.1875rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        bottom: 1.4375rem;
        left: 0.1875rem;
        animation-name: floatAnimation;
        animation-duration: 14s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    #meet-us-348 .cs-bubbles-big .cs-bubbles-big2:before {
        /* second bubble */
        content: "";
        width: 9.875rem;
        height: 9.875rem;
        border-radius: 50%;
        background: #fff;
        opacity: 1;
        position: absolute;
        display: block;
        bottom: -4.6875rem;
        right: -4.6875rem;
        animation-name: floatAnimation2;
        animation-duration: 6s;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    @keyframes floatAnimation {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-2em);
        }
        100% {
            transform: translateY(0);
        }
    }
    @keyframes floatAnimation2 {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-1em);
        }
        100% {
            transform: translateY(0);
        }
    }
}

                                