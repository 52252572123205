/*-- -------------------------- -->
<---        Side By Side        -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #sbs-1640 {
      padding: var(--sectionPadding);
      background-color: #fff;
      overflow: hidden;
    }
    #sbs-1640 .cs-container {
      width: 100%;
      /* changes to 1280px at tablet */
      max-width: 36.5rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
    }
    #sbs-1640 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      max-width: 39.375rem;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
      position: relative;
      z-index: 10;
    }
    #sbs-1640 .cs-title {
      margin: 0 0 2.5rem 0;
      color: #D4AF37;
      font-weight: 200;
      font-size: 2rem;
    }
    #sbs-1640 .cs-text {
      margin-bottom: 1rem;
      color: #5F5F5F;
      font-size: 1.2rem;
      opacity: .8;
    }
    #sbs-1640 .cs-text:last-of-type {
      margin-bottom: 2rem;
    }
    #sbs-1640 .cs-picture {
      width: 100%;
      height: auto;
      min-height: 26.25rem;
      display: block;
      position: relative;
      z-index: 1;
    }
    #sbs-1640 .cs-picture img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    #sbs-1640 .cs-stats-group {
      width: 100%;
      max-width: 35.625rem;
      display: flex;
      flex-direction: column;
    }
    #sbs-1640 .cs-stats {
      width: 100%;
      max-width: 39.375rem;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      row-gap: 1.5rem;
      column-gap: 0.75rem;
    }
    #sbs-1640 .cs-stat {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      grid-column: span 4;
      flex-direction: column;
      align-self: stretch;
      align-content: space-between;
    }
    #sbs-1640 .cs-number {
      /* 39px - 61px */
      font-size: clamp(2.4375rem, 5vw, 3.8125rem);
      line-height: 1.2em;
      font-weight: 900;
      text-align: left;
      color: var(--bodyTextColorWhite);
      display: block;
      margin: 0 0 0.25rem 0;
    }
    #sbs-1640 .cs-desc {
      font-size: 1rem;
      line-height: 1.5em;
      font-weight: 700;
      text-align: left;
      /* auto margin top will push text to bottom if there's only one line */
      margin: 0;
      color: var(--primary);
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #sbs-1640 .cs-container {
      max-width: 80rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
    }
    #sbs-1640 .cs-stats-group {
      /* 52px - 90px */
      margin-bottom: clamp(3.25rem, 7vw, 5.625rem);
      /* 60px - 120px */
      padding-top: clamp(3.75rem, 10vw, 7.5rem);
      /* 60px - 80px */
      padding-bottom: clamp(3.75rem, 8vw, 5rem);
      position: relative;
      z-index: 1;
    }
    #sbs-1640 .cs-stats-group:before {
      content: '';
      width: 200vw;
      height: 100%;
      background: #fff;
      opacity: 1;
      position: absolute;
      display: block;
      top: 100%;
      left: -100%;
      z-index: -1;
      transform: translateX(-50%);
    }
    #sbs-1640 .cs-picture {
      height: 100%;
    }
  }
                                  