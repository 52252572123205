/*-- -------------------------- -->
<---            CTA             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #cta-1692 {
      padding: var(--sectionPadding);
      background-color: #f7f7f7;
      position: relative;
      z-index: 1;
      overflow-x: hidden;
    }
    #cta-1692 .cs-container {
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
    }
    #cta-1692 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: center;
      width: 100%;
      max-width: 40rem;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: center;
      z-index: 1;
    }
    #cta-1692 .cs-title {
      max-width: 20ch;
    }
    #cta-1692 .cs-text {
      /* 28px - 40px */
      margin: 0 0 clamp(1.75rem, 3vw, 2.5rem) 0;
    }
    #cta-1692 .cs-form {
      width: 100%;
      max-width: 39.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* 8px - 20px */
      gap: clamp(0.5rem, 2vw, 1.25rem);
    }
    #cta-1692 .cs-input {
      font-size: 1rem;
      width: 100%;
      height: clamp(2.875rem, 5.5vw, 3.5rem);
      margin: 0;
      /* prevents padding from adding to width and height */
      box-sizing: border-box;
      padding: 0;
      padding-left: 1.25rem;
      border: none;
      display: block;
    }
    #cta-1692 .cs-input::placeholder {
      color: var(--bodyTextColor);
    }
    #cta-1692 .cs-button-solid {
      font-size: 1rem;
      font-weight: 700;
      /* 46px - 56px */
      line-height: clamp(2.875rem, 5.5vw, 3.5rem);
      text-align: center;
      text-decoration: none;
      min-width: 9.375rem;
      margin: 0;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
      /* 32px - 48px */
      padding: 0 clamp(2rem, 4vw, 3rem);
      color: #fff;
      background-color: var(--primary);
      display: inline-block;
      position: relative;
      z-index: 1;
    }
    #cta-1692 .cs-button-solid:before {
      content: "";
      width: 0%;
      height: 100%;
      background: #000;
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width 0.3s;
    }
    #cta-1692 .cs-button-solid:hover:before {
      width: 100%;
    }
    #cta-1692 .cs-submit {
      width: 100%;
      border: none;
    }
    #cta-1692 .cs-submit:hover {
      cursor: pointer;
    }
    #cta-1692 .cs-graphic {
      position: absolute;
    }
    #cta-1692 .cs-graphic-1 {
      /* 441px - 582px */
      width: clamp(27.5625rem, 42vw, 36.375rem);
      height: auto;
      top: 0;
      right: -13.75rem;
    }
    #cta-1692 .cs-graphic-2 {
      /* 371px - 582px */
      width: clamp(23.1875rem, 42vw, 36.375rem);
      height: auto;
      bottom: 0;
      left: -7.375rem;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #cta-1692 .cs-form {
      flex-direction: row;
    }
    #cta-1692 .cs-input {
      width: 22.5rem;
    }
    #cta-1692 .cs-submit {
      width: fit-content;
    }
    #cta-1692 .cs-graphic-1 {
      right: -8.0625rem;
    }
    #cta-1692 .cs-graphic-2 {
      left: -7.875rem;
    }
  }
                                  