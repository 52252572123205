/*-- -------------------------- -->
<---          Services          -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #services {
      padding: var(--sectionPadding);
      /* removing the side paddings to allow the cards to fit the whole page width */
      padding-right: 0;
      padding-left: 0;
      background-color: #fff;
      position: relative;
    }
    #services .cs-container {
      width: 100%;
      max-width: 156.25rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
    }
    #services .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      max-width: 80rem;
      box-sizing: border-box;
      /* 16px - 40px */
      padding: 0 clamp(1rem, 3.8vw, 2.5rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
      /* 16px - 32px */
      gap: clamp(1rem, 4vw, 2rem);
    }

    #services .cs-flex-group {
        position: relative;
        top: -10vh;
    }

    #services .cs-title {
        max-width: 15ch;
        margin: 0;
        color: #D4AF37;
        font-size: 2.5rem;
        top: 6vh;
        font-weight: 200;
        position: relative;
        text-transform: uppercase;
    }
    #services .cs-text {
      max-width: 50rem;
      color: black;
      font-size: 1.3rem;
      opacity: 0.8;

    }
    #services .cs-card-group {
      width: 100%;
      margin: 0;
      padding: 0;
      display: grid;
    }
    #services .cs-item {
      width: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
    }
    #services .cs-item:nth-of-type(2) .cs-h3,
    #services .cs-item:nth-of-type(3) .cs-h3 {
      color: #f7f7f7;
    }

    #services .cs-item:nth-of-type(2) .cs-item-text,
    #services .cs-item:nth-of-type(3) .cs-item-text {
      color: #f7f7f7;
    }
    #services .cs-item:nth-of-type(1),
    #services .cs-item:nth-of-type(4) {
      background-color: #f7f7f7;
    }
    #services .cs-item:nth-of-type(2),
    #services .cs-item:nth-of-type(3) {
      background-color: #D4AF37;
    }
    #services .cs-picture {
      width: 100%;
      max-height: 21.25rem;
      display: block;
      overflow: hidden;
    }
    #services .cs-picture img {
      width: 100%;
      height: 106%;
      object-fit: cover;
      display: block;
    }
    #services .cs-details {
      margin: 0 auto;
      /* 68px - 144px top & bottom, 24px - 32px sides*/
      padding: clamp(4.25rem, 10vw, 9rem) clamp(1.5rem, 2vw, 2rem);
      /* prevents padding from affecting height and width */
      box-sizing: border-box;
    }
    #services .cs-tag {
      /* 13px - 16px */
      font-size: clamp(0.8125rem, 2vw, 1rem);
      font-weight: 700;
      line-height: 1.2em;
      text-transform: uppercase;
      margin: 0 0 0.25rem;
      color: #fff;
      display: block;
    }
    #services .cs-h3 {
      /* 25px - 31px */
      font-size: clamp(1.5625rem, 3vw, 1.9375rem);
      font-weight: 400;
      line-height: 1.2em;
      margin: 0 0 1rem;
      color: #D4AF37;
      
    }
    #services .cs-item-text {
      font-size: 1.0rem;
      font-weight: 400;
      line-height: 1.5em;
      margin: 0;
      color: gray;
    }
    #services .cs-link {
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2em;
      text-decoration: none;
      width: 100%;
      margin: 1.5rem 0 0;
      color: #fff;
      display: flex;
      gap: 0.25rem;
    }
    #services .cs-link:hover img {
      transform: translateX(0.25rem);
    }
    #services .cs-link img {
      transition: transform 0.3s;
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 37.5rem) {
    #services .cs-content {
      flex-direction: row;
      align-items: flex-end;
    }
    #services .cs-item {
      flex-direction: row;
      align-items: center;
    }
    #services .cs-item:nth-of-type(2) .cs-h3,
    #services .cs-item:nth-of-type(3) .cs-h3 {
      color: #f7f7f7;
    }

    #services .cs-item:nth-of-type(2) .cs-item-text,
    #services .cs-item:nth-of-type(3) .cs-item-text {
      color: #f7f7f7;
    }
    #services .cs-item:nth-of-type(1) .cs-details,
    #services .cs-item:nth-of-type(3) .cs-details {
      order: 1;
    }
    #services .cs-item:nth-of-type(1) .cs-picture,
    #services .cs-item:nth-of-type(3) .cs-picture {
      order: 2;
    }
    #services .cs-details {
      width: 50%;
    }
    #services .cs-picture {
      width: 50%;
    }
    #services .cs-picture {
      height: 100%;
      max-height: none;
    }
  }
  /* Large Desktop - 1300px */
  @media only screen and (min-width: 64rem) {
    #services .cs-card-group {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: -500px;

    }

    #services .cs-title {
      max-width: 15ch;
      margin: 0;
      color: #D4AF37;
      font-size: 2.5rem;
      top: 4vh;
      font-weight: 200;
      position: relative;
      text-transform: uppercase;
  }



    #services .cs-item:nth-of-type(1) .cs-picture,
    #services .cs-item:nth-of-type(2) .cs-picture {
      order: 2;
    }

    
    #services .cs-item:nth-of-type(2) .cs-h3,
    #services .cs-item:nth-of-type(3) .cs-h3 {
      color: #f7f7f7;
    }

    #services .cs-item:nth-of-type(2) .cs-item-text,
    #services .cs-item:nth-of-type(3) .cs-item-text {
      color: #f7f7f7;
    }
    #services .cs-item:nth-of-type(3) .cs-details {
      order: 2;
    }
    #services .cs-item:nth-of-type(3) .cs-picture {
      order: 1;
    }
    
    #services .cs-item:nth-of-type(3) {
      height: 60%;
    }

    #services .cs-item:nth-of-type(4) {
      height: 60%;
    }

  }
                                  