/*-- -------------------------- -->
<---         Subscribe          -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #subscribe-1721 {
    position: relative;
    z-index: 1;
  }
  #subscribe-1721 .cs-container {
    width: 100%;
    max-width: 82rem;
    margin: auto;
    padding: var(--sectionPadding);
    padding-bottom: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
  #subscribe-1721 .cs-content {
    /* set text align to center if content needs to be centrally aligned */
    text-align: left;
    width: 100%;
    max-width: 67.375rem;
    /* -88px top -150px */
    margin-bottom: calc(clamp(5.5rem, 13vw, 9.375rem)*-1);
    /* 60px - 80px top & bottom */
    /* 16px - 128px left & right */
    padding: clamp(3.75rem, 9vw, 5rem) clamp(1rem, 8vw, 8rem);
    box-sizing: border-box;
    background-color: #111926;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* aligns content to the left, set to center to centrally align */
    align-items: flex-start;
    position: relative;
    z-index: 10;
  }
  #subscribe-1721 .cs-title {
    max-width: 20ch;
    color: var(--bodyTextColorWhite);
  }
  #subscribe-1721 .cs-text {
    margin-bottom: 1rem;
    color: var(--bodyTextColorWhite);
    opacity: .8;
  }
  #subscribe-1721 .cs-text:last-of-type {
    margin-bottom: 2rem;
  }
  #subscribe-1721 .cs-form {
    width: 100%;
    max-width: 39.375rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  #subscribe-1721 .cs-input {
    font-size: 1rem;
    width: 100%;
    height: clamp(2.875rem, 5.5vw, 3.5rem);
    margin: 0;
    padding: 0;
    padding-left: 1.25rem;
    border: none;
    /* prevents padding from adding to width and height */
    box-sizing: border-box;
    display: block;
  }
  #subscribe-1721 .cs-input::placeholder {
    color: #767676;
  }
  #subscribe-1721 .cs-button-solid {
    font-size: 1rem;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-decoration: none;
    font-weight: 700;
    text-align: center;
    margin: 0;
    color: #fff;
    min-width: 9.375rem;
    padding: 0 1.5rem;
    background-color: var(--primary);
    display: inline-block;
    position: relative;
    z-index: 1;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
  }
  #subscribe-1721 .cs-button-solid:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0%;
    background: #000;
    opacity: 1;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width 0.3s;
  }
  #subscribe-1721 .cs-button-solid:hover:before {
    width: 100%;
  }
  #subscribe-1721 .cs-submit {
    width: 100%;
    border: none;
    color: var(--bodyTextColorWhite);
  }
  #subscribe-1721 .cs-submit:hover {
    cursor: pointer;
  }
  #subscribe-1721 .cs-background {
    width: 100%;
    /* 340px - 620px */
    height: clamp(21.25rem, 47vw, 38.75rem);
    display: block;
    position: relative;
    z-index: 1;
  }
  #subscribe-1721 .cs-background img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  #subscribe-1721 .cs-floater {
    width: 28.1875rem;
    height: auto;
    opacity: .04;
    position: absolute;
    right: -13.75rem;
    top: 50%;
    z-index: -1;
    transform: translateY(-50%);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #subscribe-1721 .cs-content {
    width: 80vw;
  }
  #subscribe-1721 .cs-form {
    flex-direction: row;
  }
  #subscribe-1721 .cs-button-solid {
    width: auto;
  }
}
                                