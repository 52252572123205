/*-- -------------------------- -->
<---           Blog             -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #blog-1580 {
      padding: var(--sectionPadding);
      position: relative;
      z-index: 1;
    }
    #blog-1580 .cs-container {
      width: 100%;
      /* changes to 1280px at tablet */
      max-width: 36.5rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 64px */
      gap: clamp(3rem, 6vw, 4rem);
    }
    #blog-1580 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
    }
    #blog-1580 .cs-title {
      max-width: 20ch;
    }
    #blog-1580 .cs-button-solid {
      font-size: 1rem;
      /* 46px - 56px */
      line-height: clamp(2.875rem, 5.5vw, 3.5rem);
      text-decoration: none;
      font-weight: 700;
      text-align: center;
      margin: 0;
      color: #fff;
      min-width: 12.5rem;
      padding: 0 1.5rem;
      background-color: var(--primary);
      display: inline-block;
      position: relative;
      z-index: 1;
      /* prevents padding from adding to the width */
      box-sizing: border-box;
      transition: color .3s;
    }
    #blog-1580 .cs-button-solid:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 0%;
      background: #000;
      opacity: 1;
      top: 0;
      left: 0;
      z-index: -1;
      transition: width .3s;
    }
    #blog-1580 .cs-button-solid:hover {
      color: #fff;
    }
    #blog-1580 .cs-button-solid:hover:before {
      width: 100%;
    }
    #blog-1580 .cs-card-group {
      width: 100%;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      row-gap: 1.75rem;
      /* 16px - 20px */
      column-gap: clamp(1rem, 2.5vw, 1.25rem);
    }
    #blog-1580 .cs-item {
      text-align: left;
      list-style: none;
      overflow: hidden;
      grid-column: span 12;
      position: relative;
      z-index: 1;
      transition: box-shadow 0.3s, transform 0.3s;
    }
    #blog-1580 .cs-item:hover .cs-picture img {
      opacity: .3;
      transform: scale(1.1);
    }
    #blog-1580 .cs-item:hover .cs-h3 {
      color: var(--primary);
    }
    #blog-1580 .cs-link {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    #blog-1580 .cs-picture {
      width: 100%;
      min-height: 13.75rem;
      /* changes at tablet */
      max-height: 18.75rem;
      height: 100vw;
      /* 16px - 24px */
      margin: 0 0 clamp(1rem, 3vw, 1.5rem) 0;
      background-color: #1a1a1a;
      overflow: hidden;
      display: block;
      /* prevents flexbox from squishing it */
      flex: none;
      position: relative;
      z-index: 1;
    }
    #blog-1580 .cs-picture img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform .6s, opacity .3s;
    }
    #blog-1580 .cs-flex {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      z-index: 2;
    }
    #blog-1580 .cs-date {
      font-size: 1rem;
      line-height: 1.2em;
      font-weight: 700;
      text-align: inherit;
      margin: 0 0 2rem 0;
      padding: 0.5rem 1rem;
      /* prevents padding from affecting height and width */
      box-sizing: border-box;
      background-color: #D1D3D4;
      color: #1a1a1a;
      display: inline-block;
      position: absolute;
      left: 1rem;
      bottom: 100%;
      z-index: 2;
    }
    #blog-1580 .cs-h3 {
      /* 20px - 25px */
      font-size: clamp(1.25rem, 2.5vw, 1.5625rem);
      text-align: inherit;
      line-height: 1.2em;
      font-weight: 200;
      color: var(--headerColor);
      /* 12px - 16px */
      margin: 0 0 clamp(0.75rem, 2vw, 1rem) 0;
      transition: color .3s;
    }
    #blog-1580 .cs-item-text {
      /* 14px - 16px */
      font-size: clamp(0.875rem, 1.5vw, 1rem);
      line-height: 1.5em;
      text-align: inherit;
      margin: 0;
      color: var(--bodyTextColor);
    }
  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #blog-1580 .cs-container {
      max-width: 80rem;
    }
    #blog-1580 .cs-content {
      text-align: left;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
    #blog-1580 .cs-title {
      margin: 0;
    }
    #blog-1580 .cs-item {
      grid-column: span 4;
    }
    #blog-1580 .cs-picture {
      min-height: 11.25rem;
      /* 180px - 284px */
      height: clamp(11.25rem, 23vw, 17.75rem);
      max-height: 100%;
    }
  }
                                  