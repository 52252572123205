/*-- -------------------------- -->
<---           Video            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #video-571 {
        padding: var(--sectionPadding);
    }
    #video-571 .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        /* 48px - 64px */
        gap: clamp(3rem, 6vw, 4rem);
    }
    #video-571 .cs-content {
        text-align: left;
        width: 100%;
        max-width: 69rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* 20px - 44px */
        gap: clamp(1.25rem, 5vw, 2.75rem);
    }
    #video-571 .cs-video-title {
        /* 31px - 39px */
        font-size: clamp(1.9375rem, 6vw, 2.4375rem);
        font-weight: 200;
        line-height: 1.2em;
        text-align: inherit;
        margin: 0;
        color: #D4AF37;
        position: relative;
    }

    #video-571 .cs-big-link {
        width: 100%;
        /* 418px - 760px */
        height: clamp(26.125rem, 50vw, 47.5rem);
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    #video-571 .cs-big-link:hover .cs-background img {
        transform: scale(1.1);
    }
    #video-571 .cs-background {
        width: 100%;
        height: 100%;
        /* clips the img from overflowing the container on hover */
        overflow: hidden;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
    }
    #video-571 .cs-background:before {
        /* color overlay */
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0.72;
        background-blend-mode: multiply;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        z-index: 10;
    }
    #video-571 .cs-background img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        transition: transform 0.7s;
    }
    #video-571 .cs-link-icon {
        /* 64px - 86px */
        width: clamp(4rem, 7vw, 5.375rem);
        height: auto;
        position: absolute;
        z-index: 10;
        transition:
            transform 0.3s,
            box-shadow 0.3s;
    }
    #video-571 .cs-link-icon:hover {
        transform: scale(1.1);
    }
}

                                