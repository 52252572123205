.landing-page {
  background-color: #fff ;
}

/*-- -------------------------- -->
<---            Hero            -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
  #hero-1946 {
    padding: var(--sectionPadding);
    /* 160px - 200px */
    padding-top: clamp(10rem, 20vw, 12.5rem);
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
  #hero-1946 .cs-container {
    width: 100%;
    max-width: 80rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  #hero-1946 .cs-content {
    /* set text align to left if content needs to be left aligned */
    text-align: center;
    width: 100%;
    /* 40px - 52px */
    margin-bottom: clamp(2.5rem, 5vw, 3.25rem);
    display: flex;
    flex-direction: column;
    /* centers content horizontally, set to flex-start to left align */
    align-items: center;
    position: relative;
    z-index: 1;
  }
  #hero-1946 .cs-flex {
    /* prevents flexbox from squishing it */
    flex: none;
  }
  #hero-1946 .cs-title {
    /* 39px - 61px */
    font-size: clamp(2.438rem, 5vw, 3.813rem);
    color: #D4AF37;
    font-weight: 100;
    font-size: 3rem;
  }
  #hero-1946 .cs-text {
    color: black;
    margin-bottom: 1.25rem;
  }
  #hero-1946 .cs-button-solid {
    font-size: 1rem;
    font-weight: 700;
    /* 46px - 56px */
    line-height: clamp(2.875rem, 5.5vw, 3.5rem);
    text-align: center;
    text-decoration: none;
    min-width: 9.375rem;
    margin: 0;
    /* prevents padding from adding to the width */
    box-sizing: border-box;
    /* 32px - 48px */
    padding: 0 clamp(2rem, 4vw, 3rem);
    background-color: var(--primary);
    color: var(--bodyTextColorWhite);
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: color 0.3s;
    transition: background-color 0.3s;
  }
  #hero-1946 .cs-button-solid:hover {
    background-color: #000;
    color: #fff;
  }
  #hero-1946 .cs-picture {
    width: 100%;
    /* 280px - 510px */
    height: clamp(17.5rem, 58.6vw, 31.875rem);
    /* 16px - 20px */
    margin-bottom: clamp(1rem, 2vw, 1.25rem);
    display: block;
    position: relative;
  }
  #hero-1946 .cs-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2.5rem;
    position: absolute;
  }
  #hero-1946 .cs-card-group {
    width: 100%;
    margin: 0;
    padding: 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
    /* 16px - 20px */
    gap: clamp(1rem, 2vw, 1.25rem);
  }
  #hero-1946 .cs-item {
    text-align: center;
    list-style: none;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    /* 24px - 32px */
    padding: clamp(1.5rem, 3vw, 2rem);
    background-color: #F7F7F7;
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-column: span 12;
    position: relative;
    z-index: 1;
    transition: border-color 0.3s;
  }
  #hero-1946 .cs-item:hover {
    border-color: var(--primary);
  }
  #hero-1946 .cs-item:hover .cs-h3 {
    color: var(--primary);
  }
  #hero-1946 .cs-item:hover .cs-icon {
    transform: rotateY(360deg);
  }
  #hero-1946 .cs-icon-picture {
    margin-bottom: 1.25rem;
    perspective: 700px;
    transform-style: preserve-3d;
  }
  #hero-1946 .cs-icon {
    width: 2.5rem;
    height: auto;
    display: block;
    transition: transform 0.5s;
  }
  #hero-1946 .cs-h3 {
    font-size: 1.25rem;
    line-height: 1.5em;
    text-align: inherit;
    margin: 0;
    margin-bottom: 0.75rem;
    color: var(--headerColor);
    transition: color 0.3s;
  }
  #hero-1946 .cs-item-text {
    /* 14px - 16px */
    font-size: clamp(0.875rem, 1.5vw, 1rem);
    line-height: 1.5em;
    text-align: inherit;
    margin: 0;
    color: var(--bodyTextColor);
  }
}
/* Tablet - 768px */
@media only screen and (min-width: 48rem) {
  #hero-1946 .cs-text {
    font-size: 1.25rem;
  }
  #hero-1946 .cs-item {
    text-align: left;
    margin: 0;
    align-items: flex-start;
    grid-column: span 6;
  }
}
/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
  #hero-1946 .cs-content {
    display: flex;
    flex-direction: column;
  }
  #hero-1946 .cs-title {
    max-width: 46.875rem;
  }
  #hero-1946 .cs-item {
    grid-column: span 3;
  }
}
/* Large Desktop 1300px */
@media only screen and (min-width: 81.25rem) {
  #hero-1946 .cs-title {
    margin: 0;
  }
  #hero-1946 .cs-container {
    flex-direction: column;
  }
  #hero-1946 .cs-content {
    text-align: left;
    max-width: 80rem;
    gap: 1.25rem;
    flex-direction: row;
    align-items: flex-start;
  }
  #hero-1946 .cs-flex {
    width: 50%;
    max-width: 39.375rem;
  }
}
                                

.hero-image-landingpage {
  background-size: cover; /* Ensures the image fills the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents tiling of the image */
  height: 100vh; /* Full screen height */
  width: 100%; /* Full width */
  display: flex; /* Flexbox for centering content if needed */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  text-align: center; /* Center text alignment */
  color: white; /* Font color for any overlay text */
  image-rendering: -webkit-optimize-contrast; /* Optimize image rendering for HD */
  image-rendering: crisp-edges; /* Prevent blurry scaling */
  filter: brightness(95%); /* Slight dimming for better contrast with text */
}

.hero-video-landingpage {
  width: 100%;
  height: 100vh;
  object-fit: cover; /* Ensures the video fills the space nicely */
}




/* Slideshow Container */
.slideshow-container-landingpage {
  position: relative;
  width: 70%;
  height: 67vh;
  left: 25vh;
  overflow: hidden;
  top: 0vh;
  margin-bottom: 20vh;
}



.hero-image-slideshow {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out;
}


.prev, .next {
  position: absolute;
  top: 200vh; /* Position below slideshow */
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  font-size: 60px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.prev:hover, .next:hover {
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
}

.prev {
  left: 15vh;
}

.next {
  right: 15vh;
}


/* Video Container */
.video-container {
  display: flex;
  border: 5px solid #D4AF37;
  width: 70%;
  justify-content: center;
  background-color: rgb(0, 0, 0);
  margin: 0 auto;
}

.video-title {
  text-align: center; /* Centers the title */
  font-size: 4rem; /* Adjust size */
  margin-bottom: 10vh; /* Adds space between the title and video */
  color: #D4AF37; /* Adjust to match your theme */
  font-family: "Charm", cursive;
  font-weight: 400;
  font-style: normal;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .slideshow-container-landingpage {
    width: 100%;
    position: relative;
    left: -1vh;
    height: 60vh;
    margin-bottom: -20vh;
  }


  .hero-image-landingpage{
    width: 99%;
    height: 100%;
    position: relative;
    left: 5px;
  }

  .prev {
    position: relative;
    top: -5vh;
    width: 10%;
    left: 15%;
    font-size: 2rem;
  }

  .next {
    position: relative;
    top: -5vh;
    width: 10%;
    left: 60%;
    font-size: 2rem;
  }


  .video-container {
    width: 85%; /* Slightly reduce width */
    height: 30%; /* Adjust height for better proportions */
}

  .video-title{
    font-size: 3rem;
  }


/* Larger screen adjustments */
@media (min-width: 1200px) {
  .slideshow-container-landingpage {
    width: 80%;
    height: 75vh; /* Slightly taller slideshow */
  }

  .prev, .next {
    top: 140vh; /* Adjust button position for larger screens */
    font-size: 70px; /* Bigger buttons */
  }
}
}