/*-- -------------------------- -->
<---          Banner            -->
<--- -------------------------- -*/
/* Mobile - 360px */
@media only screen and (min-width: 0em) {
    #banner-1004 {
      padding: 0 1rem;
      /* 160px - 245px */
      padding-top: clamp(10rem, 25vw, 15.3125rem);
      padding-bottom: 7.5rem;
      background-color: #000;
      position: relative;
      z-index: 1;
    }
    #banner-1004 .cs-container {
      text-align: center;
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    #banner-1004 .cs-picture {
      width: 100%;
      margin: 0 0 0.75rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      position: relative;
    }
    #banner-1004 .cs-picture:before {
      /* left line */
      content: "";
      width: 50%;
      max-width: 9.375rem;
      height: 1px;
      background: #b4b2c7;
      opacity: 1;
      position: relative;
      display: block;
    }
    #banner-1004 .cs-picture:after {
      /* right line */
      content: "";
      width: 50%;
      max-width: 9.375rem;
      height: 1px;
      background: #b4b2c7;
      opacity: 1;
      position: relative;
      display: block;
    }
    #banner-1004 .cs-icon {
      width: 40%;
      /* 100px - 180px */
      max-width: clamp(6.25rem, 18vw, 11.25rem);
      height: auto;
      /* prevents flexbox from squishing it */
      flex: none;
    }
    #banner-1004 .cs-int-title {
      /* 39px - 61px */
      font-size: clamp(2.4375rem, 6.5vw, 3.8125rem);
      font-weight: 900;
      line-height: 1.2em;
      text-align: center;
      max-width: 43.75rem;
      margin: 0 0 1rem 0;
      color: var(--bodyTextColorWhite);
      position: relative;
    }
    #banner-1004 .cs-text {
      color: var(--bodyTextColorWhite);
      margin-bottom: 16px;
    }
    #banner-1004 .cs-background {
      width: 100%;
      height: 100%;
      opacity: 0.7;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: -1;
    }
    #banner-1004 .cs-background:before {
      /* black overlay box */
      content: "";
      width: 100%;
      height: 100%;
      background: #000;
      opacity: 0.72;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
    }
    #banner-1004 .cs-background img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
                                  