/*-- -------------------------- -->
<---          Content           -->
<--- -------------------------- -*/

/* Mobile - 360px */
@media only screen and (min-width: 0rem) {
    #content-1638 {
      padding: var(--sectionPadding);
      background-color: #f7f7f7;
      position: relative;
      z-index: 1;
    }

    #content-1638 .cs-container {
      width: 100%;
      max-width: 80rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* 48px - 100px */
      gap: clamp(3rem, 9vw, 6.25rem);
    }
    #content-1638 .cs-content {
      /* set text align to left if content needs to be left aligned */
      text-align: left;
      width: 100%;
      display: flex;
      flex-direction: column;
      /* centers content horizontally, set to flex-start to left align */
      align-items: flex-start;
    }
    #content-1638 .cs-flex {
      max-width: 38rem;
    }

    #content-1638 .cs-title { 
        font-size: 2rem;
    }
    #content-1638 .cs-h3 {
      font-size: 1.25rem;
      line-height: 1.2em;
      font-weight: 500;
      margin: 0 0 1rem 0;
      color: var(--headerColor);
    }
    #content-1638 .cs-text {
      margin-bottom: 1rem;
    }
    #content-1638 .cs-text:last-of-type {
      margin-bottom: 2rem;
    }

  }
  /* Tablet - 768px */
  @media only screen and (min-width: 48rem) {
    #content-1638:before {
      height: 63%;
    }
    #content-1638 .cs-content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 2.5rem;
    }
    #content-1638 .cs-flex {
      width: 50%;
      text-align: left;
      max-width: 38rem;
    }
    #content-1638 .cs-title {
      margin: 0;
    }
  }
                                  